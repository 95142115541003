import { HStack, Button, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { UserButton, useAuth } from "@clerk/clerk-react";
import { Text, Link as ChakraLink } from "@chakra-ui/react";

function Navigation() {
  const { isSignedIn } = useAuth();

  const colors = ["#ff4136", "#ffdc00", "#2ecc40", "#0074d9", "#b10dc9"]; // Array of colors

  const renderLights = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <circle 
        key={index} 
        cx={100 + index * 100} // Adjust spacing between lights
        cy="40" 
        r="6" 
        fill={colors[index % colors.length]} // Rotate through colors
      >
        <animate attributeName="opacity" values="1;0.5;1" dur="2s" repeatCount="indefinite"/>
      </circle>
    ));
  };

  return (
    <Box position="relative">
      {/* SVG Garland */}
      <Box position="absolute" top="0" left="0" width="100%" height="100px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 100" width="100%" height="100%" preserveAspectRatio="xMidYMid slice">
          <defs>
            <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
              <feMerge>
                <feMergeNode in="blur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            
            <pattern id="garland-pattern" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
              <path d="M 0 40 Q 25 60, 50 40 Q 75 20, 100 40" 
                    fill="none" 
                    stroke="#386641" 
                    stroke-width="8"/>
            </pattern>
          </defs>

          <rect x="0" y="20px" width="800" height="100" fill="url(#garland-pattern)" />
          
          <g filter="url(#glow)">
            {renderLights(7)} // Change the number to add more lights
          </g>
        </svg>
      </Box>

      {/* Navigation Content */}
      <HStack spacing={4} mb={4} justifyContent="flex-end" alignItems="center" pr={4} pt={4}>
        {isSignedIn && <UserButton />}
      </HStack>
    </Box>
  );
}

export function Footer() {
  return (
    <Box bg="gray.500" p={4}>
      <HStack 
        width="100%"
        justifyContent="center"
        fontSize="sm"
        color="gray.100"
        spacing={1}
      >
        <Text>Icons by</Text>
        <ChakraLink 
          href="https://icons8.com" 
          target="_blank"
          _hover={{ color: 'gray.50' }}
        >
          Icons8
        </ChakraLink>
      </HStack>
    </Box>
  );
}


export default Navigation;