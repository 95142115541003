import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { AnimatePresence, motion } from "framer-motion";
import AdventCalendar from "../components/AdventCalendar";
import CodeEditor from "../components/CodeEditor";
import MessageToUser from "../components/MessageToUser";

const AnimatedPage = ({ children }) => (
  <motion.div
    animate={{ x: 0 }}
    exit={{ x: "100%" }}
    transition={{ duration: 1, ease: "easeInOut" }}
    className="w-full h-full"
  >
    {children}
  </motion.div>
);

function ProtectedRoutes() {
  const { isSignedIn } = useAuth();
  const location = useLocation();

  if (!isSignedIn) {
    return <Navigate to="/welcome" replace />;
  }

  const shouldAnimate = location.pathname.includes('sql-challenge') || 
                       location.pathname === '/advent-calendar';

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route 
          path="/advent-calendar" 
          element={
            shouldAnimate ? (
              <AnimatedPage><AdventCalendar /></AnimatedPage>
            ) : (
              <AdventCalendar />
            )
          } 
        />
        <Route 
          path="/sql-challenge/:day" 
          element={
            shouldAnimate ? (
              <AnimatedPage><CodeEditor /></AnimatedPage>
            ) : (
              <CodeEditor />
            )
          } 
        />
        <Route 
          path="/message-to-user" 
          element={<MessageToUser />} 
        />
      </Routes>
    </AnimatePresence>
  );
}

export default ProtectedRoutes;