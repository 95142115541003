import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const Snowfall = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    const createSnowflake = () => ({
      id: Math.random(),
      x: Math.random() * 100, // random starting position (%)
      delay: Math.random() * 1, // random delay for natural look
    });

    // Initialize with some snowflakes
    setSnowflakes(Array.from({ length: 30 }, createSnowflake));

    // Add new snowflakes periodically
    const interval = setInterval(() => {
      setSnowflakes(prev => [...prev.slice(-49), createSnowflake()]);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      pointerEvents="none"
      zIndex={0}
    >
      <AnimatePresence>
        {snowflakes.map((snowflake) => (
          <motion.div
            key={snowflake.id}
            initial={{ 
              opacity: 0,
              y: -20,
              x: `${snowflake.x}vw`,
            }}
            animate={{ 
              opacity: [0, 1, 1, 0],
              y: '100vh',
              x: [`${snowflake.x}vw`, `${snowflake.x + (Math.random() * 10 - 5)}vw`]
            }}
            transition={{ 
              duration: 6,
              delay: snowflake.delay,
              ease: "linear"
            }}
            style={{
              position: 'absolute',
              width: '8px',
              height: '8px',
              backgroundColor: 'lightblue',
              borderRadius: '50%',
              filter: 'blur(1px)'
            }}
          />
        ))}
      </AnimatePresence>
    </Box>
  );
};

export default Snowfall;