import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import { Box, Heading, Text } from '@chakra-ui/react';

const CustomSignIn = () => {
  return (

    <Box display="flex" flexDirection="column" alignItems="center" >
        <Box height="40px"></Box>
        <Heading as="h1">Welcome back to the SQL Advent Calendar</Heading>
        <Text color="primary.300">By Ask Data Dawn</Text>
        <Box mt="20px" textAlign="center">
            <Text fontSize="16px" >
            By signing up, you agree to be contacted by Ask Data Dawn.
            </Text>
        </Box>
        <Box height="5px"></Box>
        <SignIn
            path="/sign-in"
            routing="path"
            signUpUrl="/sign-up"
            fallbackUrl="/welcome"
        />
        
    </Box>
  );
};

export default CustomSignIn;
