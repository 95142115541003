import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  colors: {
    primary: {
      50: "#f1f4f1",
      100: "#d8e0d8",
      200: "#b3c3b3",
      300: "#8da68d",
      400: "#668966",
      500: "#386641", // Primary base color (forest green)
      600: "#2d5234",
      700: "#223f28",
      800: "#162b1b",
      900: "#0b160d",
    },
    secondary: {
      50: "#f2f7f0",
      100: "#dbe7d6",
      200: "#b8d1ae",
      300: "#94ba85",
      400: "#7fa96b",
      500: "#6A994E", // Secondary base color (medium green)
      600: "#557d3f",
      700: "#40612f",
      800: "#2b4420",
      900: "#152210",
    },
    accent: {
      50: "#f1f4f1",
      100: "#d8e0d8",
      200: "#b3c3b3",
      300: "#8da68d",
      400: "#668966",
      500: "#BC4749", // Burgundy
    },
    sage: {
      500: "#A7C957", // Light yellow-green
    },
    sand: {
      500: "#F2E8CF", // Light beige
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "semibold",
        borderRadius: "md",
      },
      variants: {
        solid: {
          bg: "primary.500",
          color: "white",
          _hover: {
            bg: "primary.600",
          },
          _active: {
            bg: "primary.700",
          },
        },
        outline: {
          bg: "gray.50",
          color: "primary.500",
          border: "2px solid",
          borderColor: "primary.500",
          _hover: {
            bg: "primary.50",
          },
          _active: {
            bg: "primary.100",
          },
        }
      },
      defaultProps: {
        variant: "solid",  // This sets the default variant
      },
    },
    Heading: {
      baseStyle: {
        color: "primary.500",
        fontFamily: "'Mountains of Christmas', cursive",
      },
    },
    Text: {
      baseStyle: {
        color: "gray.700",
      },
      variants: {
        accent: {
          color: "primary.500", // or any other color you want for the accent
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "gray.50",
        color: "gray.800",
      },
    },
  },
});

export default theme;