import { Box } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import Navigation from "./components/Navigation";
import AuthRoutes from "./routes/AuthRoutes";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import 'typeface-mountains-of-christmas';

function App() {
  const { isLoaded, isSignedIn } = useAuth();

  // Show loading state while Clerk is initializing
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box minH="100vh" bg="gray.50" color="gray.500" px={0} py={0}>
      <Navigation />
      
      <Routes>
        {/* Public routes (login, signup, etc) */}
        <Route path="/*" element={<AuthRoutes isSignedIn={isSignedIn} />} />
        
        {/* Protected routes (advent calendar, challenges) */}
        <Route path="/app/*" element={<ProtectedRoutes />} />
      </Routes>
    </Box>
  );
}

export default App;