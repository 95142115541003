import { Routes, Route, Navigate } from "react-router-dom";
import { SignIn, SignUp } from "@clerk/clerk-react";
import Welcome from "../components/Welcome";
import CustomSignUp from "../components/CustomSignUp";
import CustomSignIn from "../components/CustomSignIn";
function AuthRoutes({ isSignedIn }) {
  // Redirect to app if already signed in
  if (isSignedIn) {
    return <Navigate to="/app/advent-calendar" replace />;
  }

  return (
    <Routes>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/sign-in/*" element={<CustomSignIn />} />
      <Route path="/sign-up/*" element={<CustomSignUp />} />
      <Route path="/" element={<Navigate to="/welcome" replace />} />
    </Routes>
  );
}

export default AuthRoutes;