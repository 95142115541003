import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { keyframes } from "@emotion/react";

const fillUpAndDown = keyframes`
  0% {
    clip-path: inset(100% 0 0 0);
  }
  45% {
    clip-path: inset(0 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  95% {
    clip-path: inset(100% 0 0 0);
  }
  100% {
    clip-path: inset(100% 0 0 0);
  }
`;

const ColorFillingGrinch = () => {
  const fillAnimation = `${fillUpAndDown} 3s ease-in-out infinite`;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" my={4} flexDirection="column">
      <Box position="relative" width="84px" height="84px">
        {/* Gray Grinch (background) */}
        <img 
          src="/grinchGray.svg" 
          alt="Gray Grinch" 
          style={{ 
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
        
        {/* Colored Grinch (animated overlay) */}
        <Box
          position="absolute"
          width="100%"
          height="100%"
          animation={fillAnimation}
        >
          <img 
            src="/grinch.svg" 
            alt="Green Grinch" 
            style={{ 
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
      <Text fontSize="sm" color="primary.400" ml={3}>Running & evaluating your query...</Text>
    </Box>
  );
};

export default ColorFillingGrinch;