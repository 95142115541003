import { useRef, useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Button, Table, Thead, Tbody, Tr, Th, Td, Heading, Tooltip } from "@chakra-ui/react";
import { Editor } from "@monaco-editor/react";
import { CODE_SNIPPETS } from "../constants";
import Output from "./Output";
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import grinchGray from '/grinchGray.svg';
import grinch from '/grinch.svg';
import { ArrowBackIcon } from "@chakra-ui/icons";
import { CHALLENGE_START_DATE, CHALLENGE_START_DATE_TEST_USER } from '../constants';
import { LockIcon } from '@chakra-ui/icons';



const CodeEditor = () => {
  const editorRef = useRef();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("sql");
  const [question, setQuestion] = useState(null);
  const { day } = useParams();
  const { user } = useUser();

    // Function to get the storage key for the day
    const getStorageKey = (userId, day) => `sql_advent_editor_${userId}_day_${day}`;

    // Load saved code from localStorage when component mounts
    useEffect(() => {
      if (day) {
        const savedCode = localStorage.getItem(getStorageKey(user.id, day));
        if (savedCode) {
          setValue(savedCode);
        } else {
          // If no saved code exists, set the default value
          setValue(CODE_SNIPPETS[language]);
        }
      }
    }, [day, language]);
  
    // Save code to localStorage whenever it changes
    useEffect(() => {
      if (day && value) {
          localStorage.setItem(getStorageKey(user.id, day), value);
      }
    }, [value, day]);
    

  const fetchQuestion = async (day) => {
    try {
      const challengeDate = new Date(
        user.id === 'user_2p8a6XDl3SdagFzWIPMIqgIljOI' || user.id === 'user_2pRIqxfWUTvRmWPr52oj82kFZd0'  || user.id === 'user_2pTuXvhQ8qJ5eWyvKoVOXDJP8Xq' || user.id === 'user_2pqlDyYH4IKVp2G3kaoqKvu6x32'
          ? CHALLENGE_START_DATE_TEST_USER 
          : CHALLENGE_START_DATE
      );
      challengeDate.setDate(challengeDate.getDate() + parseInt(day) - 1);
      
      if (new Date() < challengeDate) {
        setQuestion({
          question_day: day,
          isLocked: true,
          unlockDate: challengeDate.toLocaleString('default', { month: 'long' }) + ' ' + challengeDate.getDate()
        });
        return;
      }

      const response = await fetch(`/api/getDailyQuestion?day=${day}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setQuestion({ ...data, isLocked: false });
      return data;
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  };

  useEffect(() => {
    if (day) {
      fetchQuestion(day);
    }
  }, [day]);

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const renderTable = (data) => {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.error('Error parsing data:', error);
        return null;
      }
    }

    if (!Array.isArray(data)) {
      return null;
    }

    return data.map((tableData, index) => {
      const tableName = tableData.tableName;
      const rows = tableData.rows;

      if (!Array.isArray(rows) || rows.length === 0) {
        console.log(`No data for table: ${tableName}`);
        return null;
      }

      const columns = Object.keys(rows[0]);

      return (
        <Box key={index} mb={8} maxWidth="75%" mx="auto">
          <Text fontSize="md" mb={4} color="gray.600">
            <strong>Table name: </strong>{tableName}
          </Text>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column} textTransform="lowercase" fontSize="sm">{column}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {columns.map((column) => (
                    <Td key={column} fontSize="sm">{row[Object.keys(rows[0]).find(key => key.toLowerCase() === column)]}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    });
  };

  return (
    <Box bg="background.secondary" minH="100vh" p={4}>
      {/* <Box 
        mb={4} 
        p={4} 
        bg="red.100" 
        border="1px solid" 
        borderColor="accent.500" 
        borderRadius="md"
        color="accent.500"
        textAlign="center"
        fontWeight="medium"
      >
        Our query evaluation functionality is down right now because OpenAI is experiencing an outage. 😢 The elves are monitoring the issue. Please check back in a few hours!
      </Box> */}
      <HStack spacing={4} align="stretch" h="calc(100vh - 100px)">
        <Box w="50%" h="100%" bg="background.primary" p={4} borderRadius="md" shadow="sm">
          <Heading size="md" mb={4} color="accent.500">Day {question ? question.question_day : ' '} of SQL Advent Calendar</Heading>
          <Box overflowY="auto" h="calc(100% - 40px)">
            {question ? (
              question.isLocked ? (
                <Box>
                  <Box mb={4} />
                  <HStack spacing={2}>
                    <LockIcon boxSize={8} color="gray.700" />
                    <Text color="gray.700" fontSize="xl">
                      <Text as="span">This challenge is locked. Come back on </Text>
                      <Text as="span" fontWeight="bold">{question.unlockDate}</Text>
                      <Text as="span">!</Text>
                    </Text>
                  </HStack>
                </Box>
              ) : (
                <>
                  <Text fontWeight="bold" color="gray.600">Today's Question:</Text>
                  <Text 
                    fontSize="lg" 
                    color="gray.600" 
                    dangerouslySetInnerHTML={{ __html: question.question_text }}
                  />
                  <Box mb={4} />
                  <Box mb={4} />
                  {renderTable(question.related_table_data)}
                  <Box mb={4} />
                  <Box mb={4} />
                  <Text fontSize="md" color="gray.400" display="inline-flex" alignItems="center">
                    <strong>Question level of difficulty: </strong>  <Text as="span" mr={2} color="gray.400" fontSize="md"> </Text>
                    <Text as="span" mr={2} color="gray.400" fontSize="md">{question.difficulty === 1 ? 'Easy' : question.difficulty === 2 ? 'Medium' : 'Hard'}</Text>
                    {question ? (
                      <>
                        {Array.from({ length: question.difficulty }).map((_, index) => (
                          <img key={index} src={grinch} alt="Grinch" style={{ width: '32px', height: '32px' }} />
                        ))}
                        {Array.from({ length: 3 - question.difficulty }).map((_, index) => (
                          <img key={index + question.difficulty} src={grinchGray} alt="Gray Grinch" style={{ width: '32px', height: '32px' }} />
                        ))}
                       
                      </>
                    ) : 'Loading...'}
                  </Text>
                </>
              )
            ) : (
              <Text color="text.primary">Loading question...</Text>
            )}
          </Box>
          <Button mb={4} onClick={() => navigate('/app/advent-calendar')} colorScheme="brand" leftIcon={<ArrowBackIcon />} size="sm">
          Back to Advent Calendar
        </Button>
        </Box>

        <VStack w="50%" spacing={4} h="100%">
          <Box 
            w="100%" 
            h="50%" 
            bg="background.primary" 
            p={4} 
            borderRadius="md" 
            shadow="sm"
            position="relative"
            resize="vertical"
            overflow="auto"
            sx={{
              '&:hover': {
                cursor: 'row-resize'
              }
            }}
          >
            <HStack spacing={2} align="center">
              <Heading size="md" mb={4} color="accent.500">Write your SQL query here</Heading>
              <Tooltip label="This app uses SQLite to process your SQL queries. SQLite follows most standard SQL conventions, but may have slight differences then PostgreSQL or mySQL.">
                <Box 
                  as="span"
                  color="accent.500"
                  fontSize="md"
                  cursor="help"
                  verticalAlign="text-top"
                  mt="-16px"
                  position="relative"
                  left="-4px"
                  display="inline-block"
                >
                  ⓘ
                </Box>
              </Tooltip>
            </HStack>
            <Editor
              options={{
                minimap: { enabled: false },
                fontSize: 16
              }}
              height="calc(100% - 40px)"
              theme="vs-dark"
              language={language}
              defaultValue={CODE_SNIPPETS[language]}
              onMount={onMount}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </Box>
          
          <Box 
            w="100%" 
            flex={1}
            bg="background.primary" 
            p={4} 
            borderRadius="md" 
            shadow="sm"
            position="relative"
            overflow="auto"
          >
            <Heading size="md" mb={4} color="accent.500">Is your query correct?</Heading>
            <Output editorRef={editorRef} language={language} question={question} isLocked={question?.isLocked} />
          </Box>
        </VStack>
      </HStack>
    </Box>
  );
};

export default CodeEditor;