import { useState } from "react";
import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import SQLExecutor from './SQLExecutor';

const Output = ({ editorRef, language, question }) => {
  const { user } = useUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [executorProps, setExecutorProps] = useState(null);

  const handleRunCode = async () => {
    const query = editorRef.current.getValue();
    if (!query) return;

    setIsLoading(true);
    setExecutorProps(null);
    
    try {
      setExecutorProps({
        tables: question.related_table_data,
        query,
        question
      });

    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error.message || "Unable to run code",
        status: "error",
        duration: 6000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box h="calc(100% - 40px)">
      <Button
        variant="outline"
        colorScheme="green"
        mb={4}
        isLoading={isLoading}
        onClick={handleRunCode}
      >
        Submit Answer
      </Button>
      
      <Box
        height="calc(100% - 48px)"
        p={4}
        border="1px solid"
        borderRadius={4}
        borderColor="#333"
        position="relative"
        zIndex={1}
        overflowY="auto"
      >
        {executorProps ? (
          <SQLExecutor {...executorProps} />
        ) : (
          'Click "Submit Answer" to see if your answer is correct'
        )}
      </Box>
    </Box>
  );
};

export default Output;