import React from 'react';
import { Box, Text, Container, VStack, useColorModeValue } from '@chakra-ui/react';

const MessageToUser = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Container maxW="container.xl" py={8}>
      <Box
        bg={bgColor}
        p={6}
        borderRadius="lg"
        boxShadow="xl"
        border="1px"
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <VStack spacing={4} align="stretch">
          <Text
            fontSize="2xl"
            fontWeight="bold"
            color={textColor}
          >
            Message to User
          </Text>
          <Text color={textColor}>
            Welcome to your personalized message page! Content will be displayed here.
          </Text>
        </VStack>
      </Box>
    </Container>
  );
};

export default MessageToUser; 