import { motion } from "framer-motion";

const ShakingImage = ({ src, alt }) => {
  const getRandomRotation = () => Math.random() * 60 - 30; // Random value between -30 and 30

  const imageVariants = {
    shake: {
      rotate: [0, getRandomRotation(), 0],
      transition: {
        duration: 0.3,
        repeat: Infinity,
        repeatDelay: 1.7 // Total cycle is 2s (0.3s animation + 1.7s delay)
      }
    }
  };

  return (
    <motion.img
      src={src}
      alt={alt}
      variants={imageVariants}
      animate="shake"
      style={{
        display: 'inline',
        width: '48px',
        height: '48px',
        marginRight: '8px',
        verticalAlign: 'middle'
      }}
    />
  );
};

export default ShakingImage;