import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  Flex,
  VStack,
  HStack,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Tooltip
} from '@chakra-ui/react';
import { CheckCircleIcon, LockIcon, StarIcon, UnlockIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useClerk, useUser } from '@clerk/clerk-react';
import Confetti from 'react-confetti';
import stocking96 from '/stocking96.svg';
import tree from '/tree.svg';
import christmasStar from '/christmasStar.svg'
import ShakingImage from '../components/ShakingImage';
import CountdownDisplay from './CountdownDisplay';
import Snowfall from './Snowfall';
import { CHALLENGE_START_DATE, CHALLENGE_START_DATE_TEST_USER } from '../constants';
import SocialShare from './SocialShare';
import { SixDayMilestone, TwelveDayMilestone, EighteenDayMilestone } from './MilestoneCelebrations';

const MilestoneBanner = ({ completedCount }) => {
  if (completedCount === 6) return <SixDayMilestone />;
  if (completedCount === 12) return <TwelveDayMilestone />;
  if (completedCount === 18) return <EighteenDayMilestone />;
  return null;
};

const AdventCalendar = () => {
  const [calendar, setCalendar] = useState([]);
  const [currentDay, setCurrentDay] = useState(0);
  const [completedDays, setCompletedDays] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const { isOpen: isNotStartedOpen, onOpen: onNotStartedOpen, onClose: onNotStartedClose } = useDisclosure();
  const { isOpen: isCongratsOpen, onOpen: onCongratsOpen, onClose: onCongratsClose } = useDisclosure();
  const navigate = useNavigate();
  const { user } = useUser();
  const { signOut } = useClerk();
  const [hasShownModal, setHasShownModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        setIsLoading(true);
        console.log('Fetching user progress in AC');
        const response = await fetch(`/api/getUserProgress2?clerk_id=${user.id}`);
        if (!response.ok) {
          if (response.status === 410) {
            const data = await response.json();
            console.error(data.error);
            // window.location.href = data.redirect; // Uncomment to redirect

            return;
          }
          throw new Error('Failed to fetch user progress');
        }

        const data = await response.json();
        console.log(data);
        const completedDays = data.progress.map(item => item.question_id);
        setCompletedDays(completedDays);
        
        const today = new Date();
        const targetDate = user.id === 'user_2p8a6XDl3SdagFzWIPMIqgIljOI' || user.id === 'user_2pRIqxfWUTvRmWPr52oj82kFZd0'  || user.id === 'user_2pTuXvhQ8qJ5eWyvKoVOXDJP8Xq' || user.id === 'user_2pqlDyYH4IKVp2G3kaoqKvu6x32'
            ? new Date(CHALLENGE_START_DATE_TEST_USER) // Use test user date
            : new Date(CHALLENGE_START_DATE); // EST time
        const timeDiff = today - targetDate;
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        setCurrentDay(daysDiff >= 0 ? daysDiff : 0);
        
        // Show modal every time if calendar hasn't started
        if (daysDiff < 0) {
          onNotStartedOpen();
        }
        
        // Milestone celebrations
        const completedCount = completedDays.length;
        if (completedCount === 6 || completedCount === 12 || completedCount === 18) {
          const modalShown = sessionStorage.getItem(`milestone${completedCount}Shown`);
          if (!modalShown) {
            sessionStorage.setItem(`milestone${completedCount}Shown`, 'true');
          }
        }
        
        // Existing congratulations modal logic
        const modalShown = sessionStorage.getItem('congratsModalShown');
        if (completedDays.length === 24 && !modalShown && !hasShownModal) {
          setShowConfetti(true);
          onCongratsOpen();
          setHasShownModal(true);
          sessionStorage.setItem('congratsModalShown', 'true');
          setTimeout(() => {
            setShowConfetti(false);
          }, 10000);
        }
      } catch (error) {
        console.error('Error fetching user progress:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) fetchUserProgress();
  }, [user, isCongratsOpen, hasShownModal, onNotStartedOpen]);

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowTooltip(true);
      
      // Hide tooltip after 5 seconds
      const hideTimer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);

      // Clean up hide timer
      return () => clearTimeout(hideTimer);
    }, 3000);

    // Clean up show timer
    return () => clearTimeout(showTimer);
  }, []);


  const getStatus = (day) => {
    if (completedDays.includes(day)) return 'completed';
    if (day === currentDay) return 'current';
    if (day < currentDay) return 'unlocked';
    return 'locked';
  };


  const getStatusStyles = (status) => {
    const styles = {
      completed: {
        bg: 'secondary.200',
        borderColor: 'secondary.700',
        icon: <img src={stocking96} alt="Completed" style={{ width: '60%', height: 'auto', marginTop: '0px' }} />
      },
      current: {
        bg: 'blue.50',
        borderColor: 'blue.500',
        icon: (
          <Tooltip 
            label={currentDay === 1 ? "Click here to start your first SQL challenge!" : "You unlocked a new SQL challenge today. Solve it now!"} 
            hasArrow 
            isOpen={showTooltip} 
            bg="blue.200" 
            textAlign="center"
          >
            <Box>
              <ShakingImage src={christmasStar} alt="Current" />
            </Box>
          </Tooltip>
        )
      },
      unlocked: {
        bg: 'secondary.50',
        borderColor: 'secondary.400',
        icon: <UnlockIcon boxSize={6} color="secondary.500" />
      },
      locked: {
        bg: 'gray.50',
        borderColor: 'gray.300',
        icon: <LockIcon boxSize={6} color="gray.400" />
      }
    };
    return styles[status];
  };

  const days = Array.from({ length: 24 }, (_, i) => i + 1);

  return (
    <Box minH="100vh" bg="gray.50" py={8}>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={true}
          numberOfPieces={500}
        />
      )}

      <Container maxW="4xl">
        <Snowfall />
        <VStack spacing={8} mb={8} textAlign="center">
          <Heading as="h1" size="2xl" color="primary.500">
            <img src={tree} alt="Tree" style={{ display: 'inline', width: '64px', height: '64px', marginRight: '8px', verticalAlign: 'middle' }} />
            SQL Advent Calendar 
            <img src={tree} alt="Tree" style={{ display: 'inline', width: '64px', height: '64px', marginRight: '8px', verticalAlign: 'middle' }} />
          </Heading>
          <Text fontSize="lg">
            Complete daily SQL challenges to light up the calendar!
            <Text fontSize="md">
              A new challenge will be unlocked every day at 12PM EST. <br />
              The questions are of random difficulty, so feel free to tackle them <strong>in order</strong> or <strong>mix and match</strong>!
            </Text>
          </Text>
        </VStack>

        <MilestoneBanner completedCount={completedDays.length} />

        {!isLoading && currentDay === 0 && (

            <Box display="flex" alignItems="center" justifyContent="center" borderRadius="md" borderWidth="2px" borderColor="accent.500" flexDirection="column" >
              <Box height="10px"></Box>
              <CountdownDisplay />
              <SocialShare />
              <Box height="10px"></Box>
            </Box>

        )}

        <Flex justify="center" mt={8}>
          <HStack spacing={6} fontSize="sm">
            {[
              { icon: () => <img src={stocking96} alt="Completed" style={{ width: '24px', height: '24p' }} />, label: 'Completed', color: 'secondary.500' },
              { icon: () => <img src={christmasStar} alt="Current" style={{ width: '24px', height: '24p' }} />, label: 'Current', color: 'secondary.500' },
              { icon: UnlockIcon, label: 'Unlocked', color: 'secondary.500' },
              { icon: LockIcon, label: 'Locked', color: 'gray.400' }
            ].map(({ icon, label, color }) => (
              <HStack key={label}>
                <Icon as={typeof icon === 'function' ? icon : icon} boxSize={4} color={color} />
                <Text>{label}</Text>
              </HStack>
            ))}
          </HStack>  
        </Flex>

        <Box height="10px"></Box>

        <SimpleGrid columns={{ base: 2, sm: 4, md: 6 }} spacing={4}>
          {days.map((day) => {
            const status = getStatus(day);
            const styles = getStatusStyles(status);
            return (
              <Box
                key={day}
                bg={styles.bg}
                borderWidth="2px"
                borderColor={styles.borderColor}
                borderRadius="md"
                p={4}
                cursor={status !== 'locked' ? 'pointer' : 'not-allowed'}
                onClick={() => status !== 'locked' && navigate(`/app/sql-challenge/${day}`)}
                transition="transform 0.2s"
                _hover={{
                  transform: status !== 'locked' ? 'scale(1.05)' : 'none'
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                aspectRatio="1"
              >
                <VStack spacing={0} position="relative" w="100%" h="100%">
                  <Text fontSize="xl" fontWeight="bold" mb={1}>
                    Day {day}
                  </Text>
                  <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                    {styles.icon}
                  </Box>
                </VStack>
              </Box>
            );
          })}
        </SimpleGrid>

        <SocialShare />

        {/* Not Started Modal */}
        <Modal isOpen={isNotStartedOpen} onClose={onNotStartedClose} isCentered>
          <ModalOverlay bg="transparent" />
          <ModalContent 
            textAlign="center" 
            p={6}
            position="relative"
            zIndex="modal"
          >
            <ModalHeader fontSize="2xl" color="primary.300">
              👋 Coming Soon 
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Text fontSize="lg" color="primary.300">
                  SQL Advent Challenge has not started. 
                  <br />
                  Come back on December 1st for your 
                  <br />
                  <strong>first</strong> SQL challenge.
                </Text>
                <Text fontSize="sm" color="primary.300">We will email you when the challenge starts!</Text>
                <Button colorScheme="primary" onClick={onNotStartedClose} mt={4}>
                  Close
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Congratulations Modal */}
        <Modal isOpen={isCongratsOpen} onClose={onCongratsClose} isCentered>
          <ModalOverlay bg="transparent" />
          <ModalContent 
            textAlign="center" 
            p={6}
            position="relative"
            zIndex="modal"
          >
            <ModalHeader fontSize="2xl" color="primary.400">
              🎉 Congratulations! 🎉
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Text fontSize="lg" color="primary.300">
                  You have successfully completed the SQL Advent Calendar challenge!
                </Text>
                <Text fontSize="lg" mt={2} color="primary.300">
                  Feel free to revisit any of the questions to improve your skills.
                </Text>
                <Text fontSize="md" color="primary.300">
                  Your name has been entered to win the $100 grand prize. Our winner will be selected at random and will be notified by January 7, 2025.
                </Text>
                <Button colorScheme="primary" onClick={onCongratsClose} mt={4}>
                  Close
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default AdventCalendar;

// const AdventCalendar = () => {
//   return (
//     <Flex justify="center" align="center" minH="100vh">
//       <Box
//         border="2px solid red"
//         p={4}
//         textAlign="center"
//       >
//         <Text>We are sorry - the SQL Advent Calendar is down. The elf (i.e. Dawn) is working on fixing this right now. </Text>
        
        
//         <Text fontSize="sm">Please check back in a few hours.  Sorry for the inconvenience! </Text>
//       </Box>
//     </Flex>
//   );
// };

// export default AdventCalendar;