import { Box, Text, Heading, VStack, SlideFade } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const MilestoneBase = ({ children }) => (
  <SlideFade in={true}
    transition = {{enter: {duration: 1.0}}}
  >
    <MotionBox
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", bounce: 0.4 }}
      border="1px"
      borderColor="primary.500"
      borderRadius="lg"
      bgGradient="linear(to-r, red.50, green.50)"
      p={4}
      shadow="md"
      mb={4}
    >
      {children}
    </MotionBox>
  </SlideFade>
);

export const SixDayMilestone = () => (
  <MilestoneBase>
    <VStack spacing={2}>
      <Heading size="lg" color="red.500">🌟 1st quarter complete! 🌟</Heading>
      <Text>You've completed 6 SQL challenges - that's 25% of the calendar done!</Text>
      <Text fontSize="sm">
        <i>If you've been enjoying this challenge and would like to{' '}
        <a href="https://ko-fi.com/askdatadawn" target="_blank" rel="noopener noreferrer" style={{ color: '#72a4f2', textDecoration: 'underline' }}>
          buy me a coffee
        </a> -- I would graciously accept.</i>
      </Text>
    </VStack>
  </MilestoneBase>
);

export const TwelveDayMilestone = () => (
  <MilestoneBase>
    <VStack spacing={2}>
      <Heading size="lg" color="red.500">🏆 Halfway there! 🏆</Heading>
      <Text>12 challenges down, 12 more to go. Incredible work!</Text>
      <Text fontSize="sm">
        <i>If you've been enjoying this challenge and would like to{' '}
        <a href="https://ko-fi.com/askdatadawn" target="_blank" rel="noopener noreferrer" style={{ color: '#72a4f2', textDecoration: 'underline' }}>
          buy me a coffee
        </a> -- I would graciously accept.</i>
      </Text>
    </VStack>
  </MilestoneBase>
);

export const EighteenDayMilestone = () => (
  <MilestoneBase>
    <VStack spacing={2}>
      <Heading size="lg" color="red.500">🎯 Almost There! 🎯</Heading>
      <Text>18 challenges complete - just 6 more to go!</Text>
      <Text fontSize="sm">
        <i>If you've been enjoying this challenge and would like to{' '}
        <a href="https://ko-fi.com/askdatadawn" target="_blank" rel="noopener noreferrer" style={{ color: '#72a4f2', textDecoration: 'underline' }}>
          buy me a coffee
        </a> -- I would graciously accept.</i>
      </Text>
    </VStack>
  </MilestoneBase>
);