import { useState, useEffect } from 'react';
import { Box, Text, VStack, Flex, Badge } from "@chakra-ui/react";
import { CHALLENGE_START_DATE } from '../constants';

const FlipNumber = ({ number, label, shouldFlip }) => (
  <VStack spacing={2}>
    <Box
      bg="primary.600"
      color="white"
      px={{ base: 3, md: 6 }}
      py={{ base: 2, md: 4 }}
      borderRadius="md"
      fontSize={{ base: "xl", md: "3xl" }}
      fontWeight="bold"
      position="relative"
      overflow="hidden"
      w={{ base: "60px", md: "100px" }}
      h={{ base: "50px", md: "70px" }}
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <div
        style={{
          animation: shouldFlip ? 'flipIn 0.6s ease-out' : 'none',
          animationFillMode: 'backwards',
        }}
      >
        {number.toString().padStart(2, '0')}
      </div>
    </Box>
    <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }} textTransform="uppercase">
      {label}
    </Text>
  </VStack>
);

const CountdownDisplay = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [prevTimeLeft, setPrevTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  
  useEffect(() => {
    const timer = setInterval(() => {
      const start = new Date(CHALLENGE_START_DATE);
      console.log(start);
      const now = new Date();
      const difference = start - now;
      
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        setPrevTimeLeft(timeLeft);
        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft(null);
      }
    }, 1000);
    
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes flipIn {
        0% {
          transform: translateY(50px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  if (!timeLeft) {
    return (
      <Badge colorScheme="primary" fontSize={{ base: "sm", md: "md" }} px={4} py={2}>
        Challenge in progress!
      </Badge>
    );
  }

  return (
    <Box maxW={{ base: "100%", md: "600px" }} overflowX="hidden" px={2}>
      <Text fontSize="lg" color="primary.300" align="center">Challenge starts in...</Text>
      <Box height="5px" />
      <Flex gap={{ base: 2, md: 4 }} justify="center">
        <FlipNumber
          number={timeLeft.days}
          label="days"
          shouldFlip={timeLeft.days !== prevTimeLeft.days}
        />
        <FlipNumber
          number={timeLeft.hours}
          label="hrs"
          shouldFlip={timeLeft.hours !== prevTimeLeft.hours}
        />
        <FlipNumber
          number={timeLeft.minutes}
          label="min"
          shouldFlip={timeLeft.minutes !== prevTimeLeft.minutes}
        />
        <FlipNumber
          number={timeLeft.seconds}
          label="sec"
          shouldFlip={timeLeft.seconds !== prevTimeLeft.seconds}
        />
      </Flex>
    </Box>
  );
};

export default CountdownDisplay;

