import React from 'react';
import { SignUp } from '@clerk/clerk-react';
import { Box, Heading, Text } from '@chakra-ui/react';

const CustomSignUp = () => {
  return (

    <Box display="flex" flexDirection="column" alignItems="center" >
        <Box height="30px"></Box>
        <Heading as="h1">Sign Up for SQL Advent Calendar</Heading>
        <Text color="primary.300">By Ask Data Dawn</Text>
        <Box mt="20px" textAlign="center">
            <Text fontSize="16px" >
            By signing up, you agree to be contacted by Ask Data Dawn.
            </Text>
        </Box>
        <Box height="5px"></Box>
        <SignUp
            path="/sign-up"
            routing="path"
            signInUrl="/sign-in"
            fallbackUrl="/welcome"
            appearance={{
            elements: {
                rootBox: { 
                    width: '100%', 
                    maxWidth: '400px', 
                    margin: '0 auto', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                }, 
            },
            }}
        />
        
    </Box>
  );
};

export default CustomSignUp;
