import { VStack, HStack, IconButton, Text, useToast } from '@chakra-ui/react';
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const SocialShare = () => {
  const toast = useToast();
  const shareUrl = 'https://www.sqlcalendar.com';
  const shareText = 'I have signed up for the SQL Advent Calendar Challenge! 🎄 Join me and see how far you can get!';


  const handleShare = (platform) => {
    let shareLink = '';
    
    switch (platform) {
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'linkedin':
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'instagram':
        // Instagram doesn't have a direct web share URL, so we'll copy to clipboard
        navigator.clipboard.writeText(`${shareText}\n\n${shareUrl}`).then(() => {
          toast({
            title: 'Link copied!',
            description: 'Share this on your Instagram post or story',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        });
        return;
      default:
        return;
    }

    // Open share dialog in new window
    if (shareLink) {
      window.open(shareLink, '_blank', 'width=600,height=400');
    }
  };

  const socialButtons = [
    {
      platform: 'twitter',
      icon: <FaTwitter size="24px" />,
      ariaLabel: 'Share on Twitter'
    },
    {
      platform: 'linkedin',
      icon: <FaLinkedin size="24px" />,
      ariaLabel: 'Share on LinkedIn'
    },
    {
      platform: 'instagram',
      icon: <FaInstagram size="24px" />,
      ariaLabel: 'Share on Instagram'
    }
  ];

  return (
    <VStack spacing={2} mt={4}>
      <Text fontSize="md" color="primary.500" fontWeight="medium">
        Invite your friends to join the challenge! 🎁
      </Text>
      <HStack spacing={4}>
        {socialButtons.map(({ platform, icon, ariaLabel }) => (
          <IconButton
            key={platform}
            icon={icon}
            aria-label={ariaLabel}
            onClick={() => handleShare(platform)}
            rounded="full"
            size="lg"
            bg="white"
            color="primary.500"
            border="2px solid"
            borderColor="primary.500"
            _hover={{
              bg: 'primary.500',
              color: 'white',
              transform: 'scale(1.1)',
            }}
            transition="all 0.2s ease-in-out"
          />
        ))}
      </HStack>
    </VStack>
  );
};

export default SocialShare;