import React, { useEffect, useState } from 'react';
import initSqlJs from 'sql.js';
import { useUser } from "@clerk/clerk-react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Button} from "@chakra-ui/react";
import ColorFillingGrinch from './ColorFillingGrinch';
import { useNavigate } from 'react-router-dom';
import ReactConfetti from 'react-confetti';

const SQLExecutor = ({ tables, query, question }) => {
  const [queryOutput, setQueryOutput] = useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const executeQuery = async () => {

      setIsLoading(true);
      setQueryOutput(null);
      setValidationResult(null);
      setShowHint(false);


      let db = null;
      try {
        const tablesArray = Array.isArray(tables) ? tables : JSON.parse(tables);
        const SQL = await initSqlJs({
          locateFile: file => `/${file}`
        });
        
        db = new SQL.Database();

        for (const table of tablesArray) {
          if (!table?.rows?.length) continue;
          
          const columns = Object.keys(table.rows[0]);
          const createTableSQL = `CREATE TABLE ${table.tableName} (${
            columns.map(col => `${col} TEXT`).join(', ')
          });`;

          db.run(createTableSQL);

          for (const row of table.rows) {
            const values = columns.map(col => 
              typeof row[col] === 'string' ? `'${row[col]}'` : row[col]
            );
            const insertSQL = `INSERT INTO ${table.tableName} VALUES (${values.join(', ')});`;
            db.run(insertSQL);
          }
        }

        let queryResults = null;
        let queryError = null;
        
        try {
          queryResults = db.exec(query);
          setQueryOutput(queryResults[0]);
          console.log('queryResults', queryResults);
        } catch (sqlError) {
          console.error('SQL Error:', sqlError.message); // Add error logging
          queryError = sqlError.message;
        }

        const response = await fetch('/api/evaluateQuery', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            userQuery: query, 
            question_text: question.question_text, 
            related_table_data: question.related_table_data,
            executionStatus: queryError ? 'error' : 'success',
            executionError: queryError || ''
          })
        });
        const validation = await response.json();
        setValidationResult(validation);

        await fetch('/api/postSubmissionResult', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            clerk_id: user?.id,
            question_id: question.question_id,
            submission_result_correct: validation.correct
          })
        });
      
      } catch (err) {
        setValidationResult({ error: err.message });
      } finally {
        if (db) db.close();
        setIsLoading(false);
      }
    };

    executeQuery();
  }, [tables, query, question]);

  useEffect(() => {
    if (validationResult?.correct) {
      setShowConfetti(true);
    }
  }, [validationResult]);

  if (!validationResult && !isLoading) return null;

  return (
    <>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )}
      
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <ColorFillingGrinch />
        </Box>
      ) : (
        <>
          {queryOutput && (
            <Box overflowX="auto" mb={4}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    {queryOutput.columns.map((col, i) => (
                      <Th key={i}>{col}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {queryOutput.values.map((row, i) => (
                    <Tr key={i}>
                      {row.map((cell, j) => (
                        <Td key={j}>{cell}</Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}
          {validationResult.correct ? (
            <Box>
            <Text color="primary.500">
              Correct!! 🎉 Great work! 
            </Text>
            <Button onClick={() => navigate('/app/advent-calendar')} >
              Click here to go back to the calendar
            </Button>
            </Box>
          ) : (
            validationResult.feedback && (
              <Box>
                    <Text color="accent.500" mb={2}>
                      Sorry, that's not quite right. Give it another shot.
                    </Text>
                    {!showHint && validationResult.feedback && (
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => setShowHint(true)}
                        position="relative"
                        zIndex={2}
                        bg="white"
                        mt={2}
                        display="block"
                        width="auto"
                        color="black"
                        borderColor="gray.300"
                        opacity={1}
                        _hover={{
                          bg: "gray.50",
                          borderColor: "gray.400"
                        }}
                      >
                        Give me a hint
                      </Button>
                    )}
                    {showHint && validationResult.feedback && (
                      <Text mt={2} fontSize="md" color="gray.600">
                        {validationResult.feedback}
                      </Text>
                    )}
              </Box>
            )
          )}
        </>
      )}
    </>
  );
};

export default SQLExecutor;